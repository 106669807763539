<template>
  <div>
    <skeleton-table v-if="initialize" :rows="10" :columns="fields.length" :buttons="1" :dateSearch="true" />

    <no-records-found
      v-if="showNotFound"
      text="No transaction history found in the billing records"
    />
    
    <b-card v-else no-body class="action-buttons" :class="{'d-none': mainCardInvisiblity}">
      <b-row class="mb-1 mx-1">
        <b-col offset-lg="2" lg="10" class="mt-1">

          <div class="d-flex flex-column flex-md-row align-items-baseline">
            <v-select
              v-model="status"
              :options="statusOptions"
              :reduce="(singleStatus) => singleStatus.value"
              label="text"
              placeholder="Status"
              class="w-100 d-block mt-1"
            />
                    
            <b-form-datepicker
              v-model="dateFrom"
              :max="maxDate"
              placeholder="Date from"
              reset-button
              class="ml-md-2 rounded mt-1"
              locale="en"
            />

            <b-form-datepicker
              v-model="dateTo"
              :max="maxDate"
              placeholder="Date to"
              reset-button
              class="ml-md-2 rounded mt-1"
              locale="en"
            />

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon filter-btn ml-md-1 mt-1"
              @click="transactionRefresh"
            >
              <feather-icon icon="SearchIcon" />
            </b-button>
          </div>

          <div class="text-right">
            <small v-if="dateError" class="text-danger mr-5">Both of these fields are required for date range</small>
          </div> 

        </b-col>
      </b-row>

      <b-table
        show-empty
        empty-text="No records found from your search"
        empty-filtered-text="No records found from your search"
        hover
        striped
        :busy.sync="isBusy"
        :current-page="currentPage"
        :per-page="perPage"
        responsive
        :items="transactions"
        :fields="fields"
        ref="transactionTable"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
        <template #cell(index)="data">
          {{ data.index + 1 }}
        </template>
        <template #cell(amount)="data"> KSH {{ data.item.amount }} </template>

        <template #cell(status)="data">
          <b-button
            v-if="data.item.status == 'pending'"
            variant="outline-primary"
            class="text-capitalize"
            pill
            size="sm"
          >
            {{ data.item.status }}
          </b-button>

          <b-button
            v-else-if="data.item.status == 'completed'"
            variant="outline-success"
            class="text-capitalize"
            pill
            size="sm"
          >
            {{ data.item.status }}
          </b-button>

          <b-button
            v-else-if="data.item.status == 'error'"
            variant="outline-danger"
            class="text-capitalize"
            pill
            size="sm"
          >
            {{ data.item.status }}
          </b-button>

          <b-button
            v-else-if="data.item.status == 'rejected'"
            variant="outline-danger"
            class="text-capitalize"
            pill
            size="sm"
          >
            {{ data.item.status }}
          </b-button>

          <b-button
            v-else
            variant="outline-primary"
            class="text-capitalize"
            pill
            size="sm"
          >
            {{ data.item.status }}
          </b-button>
        </template>

        <template #cell(client)="data">
          {{ data.item.first_name }} {{ data.item.last_name }}
        </template>

      </b-table>
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="center"
        size="lg"
        v-if="!noRecordsFound"
      ></b-pagination>
    </b-card>
  </div>
</template>

<script>
import {
  BPagination,
  BTable,
  BButton,
  BSpinner,
  BCard,
  BFormDatepicker,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BCol,
  BRow,
} from "bootstrap-vue";
import vSelect from "vue-select";
import NoRecordsFound from "@/views/pages/no-records-found/NoRecordsFound.vue";
import Ripple from "vue-ripple-directive";
import SkeletonTable from "@/views/pages/shimmers/SkeletonTable.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  directives: {
    Ripple,
  },
  components: {
    BTable,
    BPagination,
    BCard,
    BButton,
    BSpinner,
    BFormDatepicker,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BCol,
    BRow,
    vSelect,
    NoRecordsFound,
    SkeletonTable,
  },
  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    return {
      fields: [
        {
          key: "index",
          label: "#",
        },
        {
          key: "amount",
          label: "TRANSACTION AMOUNT",
        },
        {
          key: "sms_units",
          label: "SMS UNITS",
        },
        {
          key: "mode",
          label: "MODE OF PAYMENT",
        },
         {
          key: "receipt_number",
          label: "Transaction Code",
        },
        {
          key: "status",
          label: "STATUS",
        },
        {
          key: "message",
          label: "STATUS DESCRIPTION",
        },
        {
          key: "format_created_at",
          label: "TRANSACTION DATE",
        },
        {
          key: "client",
          label: "CLIENT",
        },
      ],
      maxDate: new Date(today),
      dateFrom: null,
      dateTo: null,
      dateError: false,
      status: null,
      statusOptions: [
        {
          text: "Pending",
          value: "Pending",
        },
        {
          text: "Completed",
          value: "completed",
        },
        {
          text: "Pending Verification",
          value: "pending verification",
        },
        {
          text: "Rejected",
          value: "rejected",
        },
        {
          text: "Error",
          value: "error",
        }
      ],
      isBusy: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      initialize: true,
      showNotFound: false,
      noRecordsFound: false,
      mainCardInvisiblity:true,
    };
  },
  methods: {
    transactions(ctx, callback) {
      let params;
      if (this.dateFrom && this.dateTo) {
        params =
          "?page=" +
          ctx.currentPage  + 
          "&date_from=" +
          this.dateFrom +
          "&date_to=" +
          this.dateTo;
      } else {
        params = "?page=" + ctx.currentPage;
      }
      if(this.status){
        params += "&status=" + this.status;
      }
      this.$http
        .get("/credits/transaction-history" + params)
        .then((response) => {
          this.totalRows = response.data.total_pages * 10;
          // Pluck the array of items off our axios response
          const items = response.data.data;
          if (items.length == 0) {
            this.noRecordsFound = true;

            if (this.initialize) {
              this.showNotFound = true;
            }
          } else {
            this.noRecordsFound = false;
          }
          this.initialize = false;
          this.isBusy = false;
          this.mainCardInvisiblity = false;
          callback(items);
        })
        .catch((error) => {
          for (let err of error.response.data.error) {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: err,
                icon: "AlertCircleIcon",
                variant: "danger",
              },
            });
          }
          if (this.initialize) {
            this.showNotFound = true;
          }
          this.noRecordsFound = true;
          this.initialize = false;
          this.mainCardInvisiblity = false;
          callback([]);
          this.isBusy = false;
        });
      return null;
    },
    transactionRefresh() {
      if ((!this.dateFrom && this.dateTo) || (this.dateFrom && !this.dateTo)) {
        this.dateError = true;
      } else {
        this.dateError = false;
        this.$refs.transactionTable.refresh();
      }
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

@media (max-width: 767px) {
  .filter-btn  {
    display: block !important;
    width: 100%;
  }
}
@media (min-width: 768px) {
  .filter-btn  {
    border-radius: 50%;
  }
}
</style>
